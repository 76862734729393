<template>
  <div
    :id="$attrs['element-id']"
    ref="listSummary"
    tabindex="-1"
    :class="`${$attrs['class']} ${isSearchable ? ' form-select' : ''} ${readOnly ? ' opacity-0.5 bg-gray-200' : ''} ${
      isOpen ? ' select__focus' : ''
    }`"
    :data-test-id="$attrs['element-id']"
    class="relative z-1"
  >
    <div
      :class="{
        'border-red-500': hasError
      }"
      :data-cy="$attrs['element-id']"
      class="flex relative flex-col h-[37.8px] justify-center px-3 rounded 2xl:rounded-lg leading-7 cursor-pointer border"
      tabindex="0"
      @click.prevent="openClose"
    >
      <span
        class="absolute right-2 flex items-center top-2 z-[1] cursor-pointer bg-no-repeat bg-right bg-origin-content w-5 h-5"
      >
        <SvgSprite
          :class="`${isOpen ? 'rotate-180 ease-in-out duration-300' : ''}`"
          class="icon w-4 h-4 2xl:w-5 2xl:h-5 fill-transparent stroke-gray-400"
          symbol="dropdown"
        />
      </span>
      <div v-if="!ctrSelected && !isMulti" class="text-gray-600 text-sm 2xl:text-lg leading-tight">
        {{ placeHolder ? placeHolder : 'Select' }}
      </div>
      <div
        v-else
        class="pr-5 whitespace-nowrap text-ellipsis overflow-hidden block text-gray-700 text-sm 2xl:text-lg leading-tight"
      >
        <template v-if="isMulti">
          {{ getMultiText() }}
        </template>
        <template v-else>
          {{ ctrSelected[optionKey] !== '' ? ctrSelected[optionKey] : $attrs.placeholder }}
        </template>
      </div>
    </div>
    <div v-show="isOpen" class="mb-2 top-10 absolute border bg-white w-full z-[11]">
      <div class="px-3 mt-3">
        <div v-if="isSearchable">
          <input
            v-model="ctrSearch"
            :placeholder="$t('search.placeholder')"
            :tabindex="tabindex"
            class="appearance-none border rounded w-full py-2 px-3 text-slate-400 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-400 placeholder:text-sm placeholder:2xl:text-lg text-sm 2xl:text-lg focus:border-sky-500 focus:ring-2"
            type="text"
            @keyup="searchMe"
          />
        </div>
      </div>
      <ul
        :class="{ 'mb-1': isSearchable }"
        class="mt-2 p-3 pt-0 flex flex-col gap-1 overflow-auto h-auto max-h-[12rem] rounded 2xl:rounded-lg shadow-md"
        data-cy="realIdList"
      >
        <li
          v-for="(opt, i) in searchedOptions"
          :id="`listItem-${opt.id}`"
          :key="`${i}-${opt.id}-${new Date().getTime()}`"
          :class="`${isImage ? 'py-1 px-2' : 'py-2 px-3'} ${
            (ctrSelected ? opt.id === ctrSelected.id : false) ? 'bg-sky-100 font-semibold' : ''
          }`"
          class="flex hover:bg-sky-100 rounded-md flex-row items-center justify-between leading-7 gap-1"
          tabindex="0"
          @click="() => selectMe(opt)"
        >
          <div class="flex gap-3 items-center cursor-pointer">
            <div v-if="isImage" class="rounded-full border w-9 h-9 flex justify-center items-center">
              <img
                v-if="opt[imageKey]"
                :alt="``"
                :src="opt[imageKey]"
                class="border max-h-[36px] max-w-[36px] rounded-full min-h-[36px] min-w-[36px]"
              />
              <SvgSprite v-else class="h-5 2xl:h-7 fill-slate-300" symbol="placeholder-realm" />
            </div>
            <p
              :class="{
                'text-green-500': isMulti && multiValue.indexOf(opt.id) !== -1
              }"
              class="font-normal text-sm 2xl:text-lg"
            >
              {{ opt[optionKey] }}
            </p>
          </div>
          <SvgSprite
            :class="(ctrSelected ? opt.id === ctrSelected.id : multiValue.indexOf(opt.id) !== -1) ? 'flex' : 'hidden'"
            class="w-4 h-4 fill-blue-400"
            symbol="right-tick"
          />
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { SvgSprite } from 'vue-svg-sprite';
import { computed, nextTick, ref, watch, watchEffect } from 'vue';
import { onMounted, onUnmounted, useAttrs } from '#imports';
import { KeyEventCode } from '@/utils/constants';

defineOptions({
  inheritAttrs: false
});

const props = defineProps({
  options: {
    type: Array,
    required: true
  },
  search: {
    type: Boolean,
    default: false
  },
  isImage: {
    type: Boolean,
    default: false
  },
  optionKey: {
    type: String,
    required: true
  },
  imageKey: {
    type: String,
    default: 'name'
  },
  changeFunc: {
    type: Function,
    default: () => undefined
  },
  modelValue: {
    type: [Number, String, Boolean, Array, Object],
    default: ''
  },
  isSearchable: {
    type: Boolean,
    default: true
  },
  readOnly: {
    type: Boolean,
    default: false
  },
  isSortable: {
    type: Boolean,
    default: true
  },
  tabindex: {
    type: Number,
    default: 1
  },
  placeHolder: {
    type: String,
    default: ''
  },
  hasError: {
    type: Boolean,
    default: false
  }
});
const listSummary = ref();
const isOpen = ref(false);
const ctrSearch = ref('');
const searchedOptions = ref([]);
const ctrSelected = ref(null);
const isSearchable = ref(props.isSearchable);
const multiValue = ref([]);
const emit = defineEmits(['update:modelValue']);
const attrs = useAttrs();
const selectedIndex = ref(-1);

const handleClickOutside = (event) => {
  if (attrs['element-id']) {
    const dropDownElement = document.getElementById(attrs['element-id']);
    if (dropDownElement?.parentElement && !dropDownElement.parentElement.contains(event.target)) {
      isOpen.value = false;
    }
  }
};

const updateOptions = () => {
  searchedOptions.value = props.options;
  nextTick(() => {
    ctrSelected.value = searchedOptions?.value?.find(function (item) {
      if (typeof item.id === 'number') {
        return Number(item.id) === Number(props.modelValue);
      } else {
        return item.id === props.modelValue;
      }
    });
  });
};

watchEffect(() => {
  if (props.options) {
    searchedOptions.value = props.options;
  }
  if (props.modelValue) {
    updateOptions();
  }
});

const isMulti = computed(() => {
  let isMultiFlag = false;
  if (typeof props.modelValue === 'object' && Array.isArray(props.modelValue)) {
    isMultiFlag = true;
  }
  return isMultiFlag;
});

function updateSearchedOptions() {
  searchedOptions.value = searchedOptions?.value?.toSorted((a, b) =>
    typeof props.optionKey === 'number' ? a[props.optionKey] - b[props.optionKey] : true
  );
}

watch(isOpen, (newValue) => {
  if (!newValue) {
    document.removeEventListener(KeyEventCode.keyDown, handleKeyDown);
    if(listSummary.value)
      listSummary.value.blur();
  } else {
    document.addEventListener(KeyEventCode.keyDown, handleKeyDown);
    listSummary.value.focus();
  }
});

onMounted(() => {
  listSummary.value.focus();
  updateOptions();
  if (props.isSortable && Array.isArray(searchedOptions.value)) {
    updateSearchedOptions();
  }
  if (props.modelValue) {
    if (isMulti.value) {
      ctrSelected.value = props.modelValue;
    } else {
      ctrSelected.value = props.options.find(function (item) {
        if (typeof item.id === 'number') {
          return Number(item.id).toString() === Number(props.modelValue).toString();
        } else {
          return item.id === props.modelValue;
        }
      });
    }
  }

  if (isMulti.value) {
    multiValue.value = props.modelValue || [];
  }
  document.addEventListener('click', handleClickOutside);
});

watchEffect(() => {
  if (isMulti.value) {
    multiValue.value = props.modelValue;
  }
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
  if(listSummary.value)
     listSummary.value.blur();
  document.addEventListener(KeyEventCode.keyDown, handleKeyDown);
});

const openClose = () => {
  if (!props.readOnly) {
    isOpen.value = !isOpen.value;
  }
  ctrSearch.value = '';
};

const getMultiText = () => {
  return `${multiValue.value.length === 0 ? 'No' : multiValue.value.length} ${
    multiValue.value.length > 1 ? 'elements' : 'element'
  } selected`;
};

const selectMe = (opt) => {
  ctrSelected.value = opt;
  if (!isMulti.value) {
    if (typeof props.changeFunc === 'function') {
      props.changeFunc(opt.id);
    }
    emit('update:modelValue', opt.id);
    openClose();
  } else {
    const lastIndex = multiValue.value.indexOf(opt.id);
    if (lastIndex === -1) {
      multiValue.value.push(opt.id);
      emit('update:modelValue', multiValue.value);
    } else {
      multiValue.value.splice(lastIndex, 1);
      emit('update:modelValue', multiValue.value);
    }
  }
};

const searchMe = () => {
  const ctxSearch = ctrSearch?.value?.toLowerCase();
  if (props.options.length) {
    searchedOptions.value = props.options.filter(function (item) {
      return item[props.optionKey]?.toLowerCase().includes(ctxSearch);
    });
  }
};

/**
 * scrollToSelected - Scrolls the selected list item into view smoothly.
 * @returns {void}
 */

const scrollToSelected = () => {
  // Retrieve the selected list item by its dynamically set id
  const selectedListItem = document.getElementById(`listItem-${searchedOptions.value[selectedIndex.value]?.id}`);

  // Update ctrSelected with the selected item
  ctrSelected.value = searchedOptions.value[selectedIndex.value];

  // Scroll the selected list item into view smoothly
  selectedListItem?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
};

/**
 * moveSelectionUp - Moves the selection upwards in
 * the list and scrolls to the newly selected item.
 * @returns {void}
 */

const moveSelectionUp = () => {
  // Check if there is a selectable item above the current selection
  if (selectedIndex.value > 0) {
    // Decrement the selectedIndex to move the selection upwards
    selectedIndex.value--;

    // Scroll to the newly selected item
    scrollToSelected();
  }
};

/**
 * moveSelectionDown - Moves the selection downwards in the
 * list and scrolls to the newly selected item.
 * @returns {void}
 */

const moveSelectionDown = () => {
  if (selectedIndex.value < searchedOptions.value.length - 1) {
    // Increment the selectedIndex to move the selection downwards
    selectedIndex.value++;
    // Scroll to the newly selected item
    scrollToSelected();
  }
};

/**
 * selectItemByIndex - Selects an item from the searchedOptions
 * list by its index.If the item exists at the provided index,
 * it calls the selectMe function to select it.
 * @param {number} index - The index of the item to be selected.
 * @returns {void}
 */

const selectItemByIndex = (index) => {
  // Check if an item exists at the provided index in the searchedOptions list
  if (searchedOptions.value[index]) {
    // Call the selectMe function to select the item at the specified index
    selectMe(searchedOptions.value[index]);
  }
};

const handleKeyDown = (event) => {
  if (event.key === KeyEventCode.ArrowUp && isOpen.value) {
    moveSelectionUp();
  } else if (event.key === KeyEventCode.ArrowDown && isOpen.value) {
    moveSelectionDown();
  } else if (event.key === KeyEventCode.Escape && isOpen.value) {
    isOpen.value = !isOpen.value;
  } else if (event.key === KeyEventCode.Enter) {
    if (event.target.id === 'login-submit-wrapper') {
      //do nothing
    } else {
      selectItemByIndex(selectedIndex.value);
    }
  } else if (event.key === KeyEventCode.Tab) {
    // If the current selection is at the last item
    if (selectedIndex.value === searchedOptions.value.length - 1) {
      // Close the dropdown
      isOpen.value = false;
      //Default state
      selectedIndex.value = -1;
    } else {
      // Move to the next item
      selectedIndex.value++;
    }
  }
};

onMounted(() => {
  document.addEventListener(KeyEventCode.keyDown, handleKeyDown);
});
</script>
